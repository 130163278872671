namespace app.publicPages {
	angular
		.module("app.pages")
		.config(routeConfig);

	routeConfig.$inject = ["$routeProvider", "claimsConstants"];
	function routeConfig($routeProvider: ng.route.IRouteProvider, claimsConstants: app.core.security.IClaimsConstants): void {
		$routeProvider
			.when("/", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/homepage/homepage.html",
				controller: "HomepageController",
				controllerAs: "vm",
                title: "Homepage",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/documenttype/:id", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/documentType/documentType.html",
				controller: "DocumentTypeController",
				controllerAs: "vm",
                title: "Documents",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/documents", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/documents/documents.html",
				controller: "DocumentsController",
				controllerAs: "vm",
                title: "Documents",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/glossary", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/glossary/glossary.html",
				controller: "GlossaryController",
				controllerAs: "vm",
                title: "Glossary",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/legal", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/legal/legal.html",
				controller: "LegalController",
				controllerAs: "vm",
                title: "Legal",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/legal-terms-conditions", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/legalTermsConditions/legalTermsConditions.html",
				controller: "LegalTermsConditionsController",
				controllerAs: "vm",
                title: "Terms and conditions",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/legal-operating-rules", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/legalOperatingRules/legalOperatingRules.html",
				controller: "LegalOperatingRulesController",
				controllerAs: "vm",
                title: "Terms and conditions",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })	    			
			.when("/faq", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/faq/faq.html",
				controller: "FAQController",
				controllerAs: "vm",
                title: "FAQ",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			/*.when("/newsletter", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/newsletter/newsletter.html",
				controller: "NewsletterController",
				controllerAs: "vm",
                title: "Newsletter",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })*/
			.when("/links", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/links/links.html",
				controller: "LinksController",
				controllerAs: "vm",
                title: "Links",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/teleconferences", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/teleconferences/teleconferences.html",
				controller: "TeleconferencesController",
				controllerAs: "vm",
                title: "Teleconferences",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
            .when("/issues/:documentissueid/:documentissuedocumenttypeid?", <app.core.router.ICwnRoute>{
                    templateUrl: "/app/pages/issues/issues.html",
                    controller: "IssuesPageController",
                    controllerAs: "vm",
                    title: "Issues",
                    accessLevels: [claimsConstants.LoggedIn],
                    requireAuthentication: true
            })
            .when("/issuewelcome", <app.core.router.ICwnRoute>{
                    templateUrl: "/app/pages/issueWelcome/issueWelcome.html",
                    controller: "IssueWelcomeController",
                    controllerAs: "vm",
                    title: "Issues",
                    accessLevels: [claimsConstants.LoggedIn],
                    requireAuthentication: true
            })                        
			/*.when("/procedures", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/procedures/procedures.html",
				controller: "ProceduresController",
				controllerAs: "vm",
                title: "Procedures",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })*/
			.when("/finance", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/finance/finance.html",
				controller: "FinanceController",
				controllerAs: "vm",
                title: "Finances",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			/*.when("/audit-inspection-feedback", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/auditInspectionFeedback/auditInspectionFeedback.html",
				controller: "AuditInspectionFeedbackController",
				controllerAs: "vm",
                title: "Audit inspection feedback",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })*/
            .when("/logout", <app.core.router.ICwnRoute>{
				templateUrl: "/app/pages/logout/logout.html",
				controller: "LogoutController",
				controllerAs: "vm",
				title: "Logout",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
			});
	}
}