namespace app.pages {
    "use strict";

    export interface IPageConstants {
        Homepage: number;
        Legal: number;
        LegalTermsConditions: number;
        LegalOperatingRules: number;
        FAQ: number;
        Links: number;
        Glossary: number;
        Issues: number;

        Finance: number;
        //Procedures: number;
        Documents: number;
        //AuditInspectionFeedback: number;
        Teleconferences: number;
        //Newsletter: number;
    }

    angular
        .module("app.pages")
        .constant("pageConstants", <IPageConstants>{
            Homepage: 3,
            Legal: 5,
            LegalTermsConditions: 2,
            LegalOperatingRules: 9,
            FAQ: 7,
            Links: 8,
            Glossary: 6,
            Issues: 14,    // (Projects)

            Finance: 10,
            //Procedures: 16,
            Documents: 12,
            //AuditInspectionFeedback: 13,
            Teleconferences: 4,
            //Newsletter: 103
        });
}
